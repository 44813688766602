/* mobile */
.home-container {
  display: flex;
  flex-direction: column;
  height: auto;
  width: calc(100% - 20px);
  margin-top: 60px;
  padding: 10px;
  padding-bottom: 20px;
}

.home-item {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  max-width: 100%;
  overflow: hidden;
  background: url(../Images/purplebox.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.home-item img {
  height: 100%;
  width: auto;
  object-fit: cover;
}

.home-caption {
  background-color: rgba(255, 255, 255, 0.35);
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  line-height: 146%;
  font-size: 13px;
  text-align: center;
  position: fixed;
  bottom: 24px;
  max-width: 80%;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 4px 2px;

}

.home-caption span {
  font-style: italic;
}

/* desktop */
@media screen and (min-width: 600px) {

  .home-container {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    column-gap: 32px;
    box-sizing: border-box;

    width: calc(100% - 10px);
    margin-top: 0;
    padding: 20px 10px;
  }

  .home-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    grid-column-start: 1;
    grid-column-end: 13;
    max-width: 100%;
    height: calc(100vh - 84px);
    padding: 20px 0;
  }

  .home-item img {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    margin-top: 24px;
  }

  .home-caption {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    line-height: 146%;
    font-size: 13px;
    text-align: center;
    position: relative;
    bottom: 0px;
    grid-column-start: 1;
    grid-column-end: 13;
    margin: 0;
    padding-top: 2px;
  }

  .home-caption span {
    font-style: italic;
  }

}
