/* mobile */

.work-container {
  margin-top: 48px;
}

div.work-image-row {
display: block;
width: 100% - 20px;
margin-left: 10px;
margin-right: 10px;
}

div.work-image-row img {
max-height: 100%;
max-width: 100%;
object-fit: cover;
margin-top: 24px;
margin-bottom: 24px;
}

div.work-artwork-details {
position: relative;
}

div.work-artwork-details p {
position: absolute;
bottom: -20px;
line-height: 130%;
font-family: "Montserrat", sans-serif;
text-align: left;
font-weight: 400;
font-size: 13px;
}

.work-image-row span {
font-style: italic;
}


/* desktop */
@media screen and (min-width: 600px) {

  .work-container {
    margin-top: 0px;
    padding-left: 10px;
  }

  div.work-image-row {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    column-gap: 32px;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
  }
  
  div.work-image-row img {
    grid-column-start: 1;
    grid-column-end: 8;
    max-height: 100%;
    object-fit: cover;
    margin-top: 32px;
    margin-bottom: 32px;
  }
  
  div.work-artwork-details {
    position: relative;
    grid-column-start: 8;
    grid-column-end: 11;
  }
  
  div.work-artwork-details p {
    position: absolute;
    bottom: 18px;
    font-family: "Montserrat", sans-serif;
    text-align: left;
    font-weight: 200;
    font-size: 14px;
  }
  
  .work-image-row span {
    font-style: italic;
  }
}