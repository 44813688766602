
.contact-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: auto;
    width: calc(100% - 20px);
    margin-top: 96px;
}

.contact-container-image {
  width: 100%;
  padding: 0 10px;
}

.contact-container-image img {
  width: 100%;
}

.contact-container p {
  color: black;
  font-family: "Montserrat", sans-serif;
  font-size: 16;
  line-height: 88%;
  display: block;
  padding: 24px 10px;
}

.contact-container a, a:visited {
  color: magenta;
  text-decoration: none;
  border-bottom: 1px solid magenta;
}

/* desktop */

@media screen and (min-width: 600px) {

  .contact-container {
    display: flex;
    flex-direction: column-reverse;
  }

  .contact-container-image {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    column-gap: 32px;
    width: 100%;
  }
  
  .contact-container p {
    grid-column-start: 0;
  }

  .contact-container-image img {
    grid-column-start: 1;
    grid-column-end: 8;
    min-width: 344px;
  }

 }

