@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;1,100;1,200;1,300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* mobile */

.app-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-align-items: stretch;
            align-items: stretch;
}

.app-container-nav {
    width: 100%;
    padding-right: 28px;
}

.app-container-content {
    width: 100% - 180px;
}

/* desktop */

@media screen and (min-width: 600px) {
    .app-container {
        -webkit-flex-direction: row;
                flex-direction: row;
    }
    .app-container-nav {
        width: 228px;
        -webkit-flex: 0 0 auto;
                flex: 0 0 auto;
    }
    .app-container-content {
        width: 100%;
    }
}

/* mobile */
.home-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: auto;
  width: calc(100% - 20px);
  margin-top: 60px;
  padding: 10px;
  padding-bottom: 20px;
}

.home-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: calc(100vh - 60px);
  max-width: 100%;
  overflow: hidden;
  background: url(/static/media/purplebox.f93488a4.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.home-item img {
  height: 100%;
  width: auto;
  object-fit: cover;
}

.home-caption {
  background-color: rgba(255, 255, 255, 0.35);
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  line-height: 146%;
  font-size: 13px;
  text-align: center;
  position: fixed;
  bottom: 24px;
  max-width: 80%;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 4px 2px;

}

.home-caption span {
  font-style: italic;
}

/* desktop */
@media screen and (min-width: 600px) {

  .home-container {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-column-gap: 32px;
    -webkit-column-gap: 32px;
            column-gap: 32px;
    box-sizing: border-box;

    width: calc(100% - 10px);
    margin-top: 0;
    padding: 20px 10px;
  }

  .home-item {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    grid-column-start: 1;
    grid-column-end: 13;
    max-width: 100%;
    height: calc(100vh - 84px);
    padding: 20px 0;
  }

  .home-item img {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    margin-top: 24px;
  }

  .home-caption {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    line-height: 146%;
    font-size: 13px;
    text-align: center;
    position: relative;
    bottom: 0px;
    grid-column-start: 1;
    grid-column-end: 13;
    margin: 0;
    padding-top: 2px;
  }

  .home-caption span {
    font-style: italic;
  }

}

/* mobile */

.work-container {
  margin-top: 48px;
}

div.work-image-row {
display: block;
width: 100% - 20px;
margin-left: 10px;
margin-right: 10px;
}

div.work-image-row img {
max-height: 100%;
max-width: 100%;
object-fit: cover;
margin-top: 24px;
margin-bottom: 24px;
}

div.work-artwork-details {
position: relative;
}

div.work-artwork-details p {
position: absolute;
bottom: -20px;
line-height: 130%;
font-family: "Montserrat", sans-serif;
text-align: left;
font-weight: 400;
font-size: 13px;
}

.work-image-row span {
font-style: italic;
}


/* desktop */
@media screen and (min-width: 600px) {

  .work-container {
    margin-top: 0px;
    padding-left: 10px;
  }

  div.work-image-row {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-column-gap: 32px;
    -webkit-column-gap: 32px;
            column-gap: 32px;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
  }
  
  div.work-image-row img {
    grid-column-start: 1;
    grid-column-end: 8;
    max-height: 100%;
    object-fit: cover;
    margin-top: 32px;
    margin-bottom: 32px;
  }
  
  div.work-artwork-details {
    position: relative;
    grid-column-start: 8;
    grid-column-end: 11;
  }
  
  div.work-artwork-details p {
    position: absolute;
    bottom: 18px;
    font-family: "Montserrat", sans-serif;
    text-align: left;
    font-weight: 200;
    font-size: 14px;
  }
  
  .work-image-row span {
    font-style: italic;
  }
}
/* mobile */
  .about-text {
    margin-top: 0;
  }

  .about-container a {
    text-decoration: none;
    border-bottom: 1px solid magenta;
    color: magenta;
    font-weight: 600;
  }

  .about-container img {
    object-fit: cover;
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  .about-text h2 {
    font-weight: 800;
    line-height: 1.2em;
  }

  .about-container {
    display: block;
    margin: 0;
    width: calc(100% - 20px);
    margin-top: 72px;

    margin-left: 10px;
    margin-right: 10px;
    font-family: "Montserrat", sans-serif;
    line-height: 1.4em;
    font-weight: 400;
    padding-bottom: 32px;
  }

  .about-image-desktop {
    display: none;
  }

  .about-presslinks {
    font-weight: 600;
    padding-top: 0;
    padding-bottom: 40px;
  }
  
  .about-presslinks p {
    padding: 4px 0;
  }
  
  .about-presslinks a {
    text-decoration: none;
    color: magenta;
    border-bottom: 1px solid magenta;
    padding-bottom: 0.5px;
    font-weight: 600;
  }


@media screen and (min-width: 600px) {
  .about-container {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-column-gap: 32px;
    -webkit-column-gap: 32px;
            column-gap: 32px;
    box-sizing: border-box;
    width: calc(100% - 48px);
    margin-right: 48px;
    font-family: "Montserrat", sans-serif;
    line-height: 1.4em;
    font-weight: 400;
    margin-top: 0;

  }

  .about-image-desktop {
    display: none;
  }

  .about-image-mobile {
    display: block;
  }
  
  .about-text {
    margin-top: 104px;
    grid-column-start: 1;
    grid-column-end: 12;
  }
  
  .about-text a {
    text-decoration: none;
    color: magenta;
  }
  
  .about-text a:hover {
    color: #29fd2f;
    border-bottom: 1px solid #29fd2f;
  }
  
  .about-image-desktop img {
    object-fit: cover;
    width: 86%;
    max-height: 100%;
    margin-top: 104px;
  }
  
  .about-image-mobile {
    width: 100%;
    height: auto;
    text-align: center;
  }
}

/* desktop */
@media screen and (min-width: 950px) {
  .about-text {
    margin-top: 104px;
    grid-column-start: 1;
    grid-column-end: 7;
  }

  .about-image-desktop {
    text-align: center;
    grid-column-start: 7;
    grid-column-end: 13;
    margin-top: 16px;
  }

  .about-image-desktop {
    display: block;
  }

  .about-image-mobile {
    display: none;
  }
}

.contact-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    height: auto;
    width: calc(100% - 20px);
    margin-top: 96px;
}

.contact-container-image {
  width: 100%;
  padding: 0 10px;
}

.contact-container-image img {
  width: 100%;
}

.contact-container p {
  color: black;
  font-family: "Montserrat", sans-serif;
  font-size: 16;
  line-height: 88%;
  display: block;
  padding: 24px 10px;
}

.contact-container a, a:visited {
  color: magenta;
  text-decoration: none;
  border-bottom: 1px solid magenta;
}

/* desktop */

@media screen and (min-width: 600px) {

  .contact-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }

  .contact-container-image {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-column-gap: 32px;
    -webkit-column-gap: 32px;
            column-gap: 32px;
    width: 100%;
  }
  
  .contact-container p {
    grid-column-start: 0;
  }

  .contact-container-image img {
    grid-column-start: 1;
    grid-column-end: 8;
    min-width: 344px;
  }

 }



.mobile-nav-list {
  list-style-type: none;
  text-align: center;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  overflow-x: hidden;
  transition: 0.5s;
  background-color: white;
}

.mobile-nav-list li {
  background-color: white;
  list-style-type: none;
  margin-left: -48px;
  font-size: 36px;
  padding: 0;
  top: 64px;
  position: relative;
  width: 100%;
  text-align: center;
}

.mobile-nav-list a {
  display: block;
  color: black;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  line-height: 2em;
  text-decoration: none;
  position: relative;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
  margin: 24px 0;
}

.mobile-nav-name h1 {
  color: black;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 24px;
  line-height: 124%;
  position: absolute;
  top: 0;
  left: 20px;
  z-index: 100;
  margin-top: 16px;
}

/* Icon */
.mobile-menu-button-icon {
  position: absolute;
  top: .5px;
  right: 0px;
  width: 60px;
  height: 60px;
  background: transparent;
  border: none;
  z-index: 200;
}

.mobile-menu-button-icon img {
  width: 32px;
  height: 32px;
}

/* Resizing */

.desktop-nav-container {
  display: none;
}

button.mobile-button {
  display: block;
  position: fixed;
}

.mobile-nav-list a {
  color: black;
}

/* desktop menu navigation */
@media screen and (min-width: 600px) {
  .desktop-nav-container {
    font-family: "Montserrat", sans-serif;
    display: inline-block;
    width: 180px;
    height: 100%;
    margin-top: 28px;
    margin-left: 48px;
    position: fixed;
  }

  .desktop-nav-list {
    list-style-type: none;
    padding: 0;
  }

  .desktop-nav-list a {
    display: block;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: black;
    text-decoration: none;
    line-height: 170%;
  }

  .desktop-nav-list a:hover {
    color: #29fd2f;
  }

  .desktop-nav-name {
    color: #000;
    font-weight: 800;
    font-size: 26px;
    line-height: 130%;
    letter-spacing: 0%;
    margin-top: 0;
    margin-bottom: 24px;
  }

  .built-by-footer {
    color: black;
    font-size: 13px;
    font-weight: 300;
    bottom: 40px;
    position: fixed;
  }

  .desktop-nav-container {
    display: inline-block;
  }

  .mobile-button {
    display: none;
  }

  .mobile-nav-name {
    display: none;
  }
}

