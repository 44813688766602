@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;1,100;1,200;1,300&display=swap');

/* mobile */

.app-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
}

.app-container-nav {
    width: 100%;
    padding-right: 28px;
}

.app-container-content {
    width: 100% - 180px;
}

/* desktop */

@media screen and (min-width: 600px) {
    .app-container {
        flex-direction: row;
    }
    .app-container-nav {
        width: 228px;
        flex: 0 0 auto;
    }
    .app-container-content {
        width: 100%;
    }
}
