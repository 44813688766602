
.mobile-nav-list {
  list-style-type: none;
  text-align: center;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  overflow-x: hidden;
  transition: 0.5s;
  background-color: white;
}

.mobile-nav-list li {
  background-color: white;
  list-style-type: none;
  margin-left: -48px;
  font-size: 36px;
  padding: 0;
  top: 64px;
  position: relative;
  width: 100%;
  text-align: center;
}

.mobile-nav-list a {
  display: block;
  color: black;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  line-height: 2em;
  text-decoration: none;
  position: relative;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
  margin: 24px 0;
}

.mobile-nav-name h1 {
  color: black;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 24px;
  line-height: 124%;
  position: absolute;
  top: 0;
  left: 20px;
  z-index: 100;
  margin-top: 16px;
}

/* Icon */
.mobile-menu-button-icon {
  position: absolute;
  top: .5px;
  right: 0px;
  width: 60px;
  height: 60px;
  background: transparent;
  border: none;
  z-index: 200;
}

.mobile-menu-button-icon img {
  width: 32px;
  height: 32px;
}

/* Resizing */

.desktop-nav-container {
  display: none;
}

button.mobile-button {
  display: block;
  position: fixed;
}

.mobile-nav-list a {
  color: black;
}

/* desktop menu navigation */
@media screen and (min-width: 600px) {
  .desktop-nav-container {
    font-family: "Montserrat", sans-serif;
    display: inline-block;
    width: 180px;
    height: 100%;
    margin-top: 28px;
    margin-left: 48px;
    position: fixed;
  }

  .desktop-nav-list {
    list-style-type: none;
    padding: 0;
  }

  .desktop-nav-list a {
    display: block;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: black;
    text-decoration: none;
    line-height: 170%;
  }

  .desktop-nav-list a:hover {
    color: #29fd2f;
  }

  .desktop-nav-name {
    color: #000;
    font-weight: 800;
    font-size: 26px;
    line-height: 130%;
    letter-spacing: 0%;
    margin-top: 0;
    margin-bottom: 24px;
  }

  .built-by-footer {
    color: black;
    font-size: 13px;
    font-weight: 300;
    bottom: 40px;
    position: fixed;
  }

  .desktop-nav-container {
    display: inline-block;
  }

  .mobile-button {
    display: none;
  }

  .mobile-nav-name {
    display: none;
  }
}
